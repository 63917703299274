import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row className="justify-content-center"> {/* Centraliza o conteúdo */}
          <Col md={8} className="home-about-description text-center"> {/* Centraliza o texto dentro do Col */}
            <h1 style={{ fontSize: "2.6em" }}>
              VAMOS FAZER <span className="purple"> NEGÓCIO </span> ?
            </h1>
            <p className="home-about-body">
              Conectamos seu carro para mais de 
              <b className="purple"> 21 mil </b> compradores e investidores em todo o país.
              <br />
              <br />Dessa forma conseguimos vender seu veículo muito mais <b className="purple"> rápido</b>
              <br /> e oferecer as <b className="purple">melhores propostas</b> na hora.
              <br />
              <br />
              Tudo isso realizado com muita agilidade e &nbsp;
              <i>
                <b className="purple">sem burocracia</b>.
                <br />Vem para a <b className="purple">Vaapty</b> que sai negócio!
              </i>
              <br />
              <br />
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
