import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about2.png";
import FAQ from "./FAQ"; // Importando o componente de FAQ

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Sobre <strong className="purple">NÓS</strong>
            </h1>
            <p>
            A Vaapty foi fundada através da expertise de mercado do sócio-fundador, ao perceber a necessidade de inovação no setor automotivo após mais de 20 anos de atuação na área.

Com o intuito de mobilizar o mercado de compra e venda de veículos de forma prática, segura e sem burocracia, tornando-se pioneira por ser a única empresa do setor a investir em tecnologia e inovação.

Somos a franquia do segmento que mais cresce no país, atualmente contando com + de 150 franquias, sendo a maior do nosso segmento. Por isso estamos sempre em busca de parceiros para expandir nossa marca, novos talentos, pessoas engajadas, ambiciosas, e que pensem fora da caixa!

Na Vaapty, acreditamos que vender ou comprar um veículo deve ser uma experiência fácil e sem estresse para nossos clientes, trabalhamos incansavelmente para oferecer a melhor experiência e o melhor atendimento em franchising do Brasil!

E é com essa mentalidade que nos tornamos a maior referência do setor como a franquia que mais forma milionários no país. No ano passado, movimentamos mais de R$ 1 bilhão em vendas e faturamos mais de R$ 90 milhões
            </p>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        
        
      </Container>
    </Container>
  );
}

export default About;
