import React, { useState } from "react";
import { Container, Row, Accordion, Card, Button } from "react-bootstrap";

function FAQSection() {
  const [activeKey, setActiveKey] = useState("0"); // Estado para controlar a pergunta ativa

  const faqData = [
    {
      question: "Como funciona?",
      answer: "Você agenda uma avaliação totalmente gratuita. Depois de agendar sua avaliação, seu carro já tem data e hora para ser vendido. Em apenas 40 minutos, conseguimos a melhor proposta para você. Não se preocupe com a parte burocrática: documentos, transferências e todo o processo ficam por nossa conta.",
    },
    {
      question: "Vende rápido mesmo?",
      answer: "Sim! Conseguimos muitas propostas para a venda do seu carro de forma rápida, com pagamento à vista no Pix.",
    },
    {
      question: "Vendem carros com dívidas?",
      answer: "Sim, vendemos carros com débitos atrasados, como IPVA, multas e financiamentos em aberto. Venha para a Vaapty agora mesmo! O que você está esperando?",
    },
    {
      question: "Vendem carros que têm leilão ou sinistros?",
      answer: "Sim, vendemos independentemente do histórico do seu veículo.",
    },
    {
      question: "Até que ano de carros vocês vendem?",
      answer: "Não importa o ano, desde que o carro esteja em funcionamento e tenha documentação para transferência.",
    },
    {
      question: "Vocês aceitam trocas?",
      answer: "Não trabalhamos com trocas ou financiamentos, apenas com a venda do seu veículo à vista.",
    },
    {
      question: "Precisa preparar o carro para venda?",
      answer: "Não é necessário lavar, arrumar detalhes, trocar pneus ruins ou pintar peças. Não tem problema, vendemos o carro no estado em que se encontra.",
    },
    {
      question: "Vocês compram o carro?",
      answer: "Não compramos seu veículo. Fazemos a intermediação da venda, conectando vendedores e compradores através da nossa plataforma digital para mais de 25.000 compradores e investidores de todo o Brasil.",
    },
    {
      question: "Quem compra os veículos?",
      answer: "Os veículos são adquiridos por compradores e investidores cadastrados de todo o Brasil. Não vendemos para pessoas físicas ou consumidores finais, apenas para CNPJ.",
    },
  ];

  const handleToggle = (key) => {
    // Verifica se a pergunta já está ativa; se estiver, colapsa, senão expande.
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <Container fluid className="faq-section" style={{ paddingTop: "80px" }}>
      <Row className="justify-content-center my-4">
       <h2> 
        <span style={{ color: "white" }}>Perguntas</span> <span className="purple">Frequentes</span>
       </h2>

      </Row>

      <Row className="faq justify-content-center">
        <Accordion activeKey={activeKey} className="w-75">
          {faqData.map((faq, index) => (
            <Card key={index} className="mb-3">
              <Card.Header>
                <Accordion.Button
                  as={Button}
                  variant="link"
                  onClick={() => handleToggle(index.toString())} // Controla a abertura
                  aria-controls={`faq-${index}`}
                >
                  {faq.question}
                </Accordion.Button>
              </Card.Header>
              <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body>{faq.answer}</Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Row>
    </Container>
  );
}

export default FAQSection;
